import { TextInput, Title } from '@mantine/core'
import { LuSearch } from 'react-icons/lu'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'
import MenuOptions from '../../../shared/components/menu/menu-options'
import { healthcareSpecialtyOptions } from '../../healthcare-professional/utils'
import { useClinicalTrialsSearchTerm, useClinicalTrialsSearchTermActions } from '../store/clinical-trials-search-term-store'
import { useClinicalTrialFilter, useClinicalTrialFilterActions } from '../store/clinical-trials-filter-store'
import { useResearchCentersQuery } from '../queries/research-center-queries'
import { buildResearchCenterOptions } from '../utils'

export default function ClinicalTrialListFilterMenu() {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const searchQueryParam = searchParams.get('search')

  const { data: researchCentersOptions } = useResearchCentersQuery({ select: buildResearchCenterOptions })

  const { setSearchTerm } = useClinicalTrialsSearchTermActions()
  const searchTerm = useClinicalTrialsSearchTerm()

  const filter = useClinicalTrialFilter()
  const { setHealthcareSpecialties, setResearchCenters } = useClinicalTrialFilterActions()

  const handleSelectResearchCenter = (optionValue: string) => {
    const researchCenter = filter.researchCenterId !== optionValue ? optionValue : null
    setResearchCenters(researchCenter)
  }

  const handleSelectSpecialty = (optionValue: string) => {
    const healthcareSpecialties = filter.healthcareSpecialties.includes(optionValue)
      ? filter.healthcareSpecialties.filter((value) => value !== optionValue)
      : [...filter.healthcareSpecialties, optionValue]

    setHealthcareSpecialties(healthcareSpecialties)
  }

  useEffect(() => {
    setSearchTerm(searchQueryParam || '')
  }, [setSearchTerm, searchQueryParam])

  return (
    <div className="flex justify-center w-full h-full rounded-xl py-12 lg:py-14 2xl:py-16 bg-cover bg-no-repeat bg-center bg-clinical-trial-background-image">
      <div className="flex flex-col w-[93%] gap-7 items-center justify-center">
        <Title order={3} fw={600} className="text-white">{t('clinicalTrial:title')}</Title>
        <div className="flex flex-col gap-5 sm:w-[80%] w-[98%]">
          <TextInput
            size="md"
            className="w-full"
            placeholder={t('clinicalTrial:search-placeholder')}
            radius="lg"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            rightSection={<LuSearch size={18} />}
          />
          <div className="flex gap-6 justify-start mx-1">
            <Title order={5} fw={600} className="text-white">{t('common:filters')}</Title>
            <div className="flex gap-3">
              <MenuOptions
                title={t('clinicalTrial:research-center')}
                options={researchCentersOptions || []}
                onOptionSelect={handleSelectResearchCenter}
                selectedOptions={filter.researchCenterId ? [filter.researchCenterId] : []}
              />
              <MenuOptions
                title={t('clinicalTrial:specialty')}
                options={healthcareSpecialtyOptions}
                onOptionSelect={handleSelectSpecialty}
                selectedOptions={filter.healthcareSpecialties}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
