import ErrorCodes from '../../../libs/api/enum/code-messages'
import errorCodesMessages from './error-code-messages'

const getErrorMessage = (errorCode?: ErrorCodes) => {
  if (!errorCode || !(errorCode in errorCodesMessages)) {
    return errorCodesMessages.default
  }

  return errorCodesMessages[errorCode as keyof typeof errorCodesMessages]
}

export default getErrorMessage
