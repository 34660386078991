import { Stepper } from '@mantine/core'
import { useEffect, useState } from 'react'
import { TbCheck, TbCircleFilled } from 'react-icons/tb'
import { useTranslation } from 'react-i18next'
import { useAuthUserIsUserAuthenticated } from '../features/auth/store/auth-user-store'
import HealthcareProfessionalAuth from '../features/healthcare-professional/components/healthcare-professional-auth'
import HealthcareProfessionalApplicantForm from '../features/healthcare-professional/components/healthcare-professional-applicant-form'
import EligibilityTestForm from '../features/eligibility-test/components/eligibility-test-form'
import EligibilityTestResult from '../features/eligibility-test/components/eligibility-test-result'
import { ExtendedHealthcareProfessionalApplication } from '../models'
import EligibilityTestResponseResult from '../interfaces/eligibility-test/eligibility-test-result'

export default function HealthCareProfessionalApplication() {
  const { t } = useTranslation()

  const [currentStep, setCurrentStep] = useState(0)
  const [healthcareProfessionalApplication, setHealthcareProfessionalApplication] = useState<ExtendedHealthcareProfessionalApplication | null>(null)
  const [eligibilityTestResponseResult, setEligibilityTestResponseResult] = useState<EligibilityTestResponseResult | null>(null)

  const processHealthcareProfessionalApplication = (newHealthcareProfessionalApplication: ExtendedHealthcareProfessionalApplication) => {
    setCurrentStep(2)
    setHealthcareProfessionalApplication(newHealthcareProfessionalApplication)
  }

  const completeHealthcareProfessionalApplication = (newEligibilityTestResponseResult: EligibilityTestResponseResult) => {
    setEligibilityTestResponseResult(newEligibilityTestResponseResult)
  }

  const isUserAuthenticated = useAuthUserIsUserAuthenticated()

  useEffect(() => {
    if (isUserAuthenticated) {
      setCurrentStep(1)
    }
  }, [isUserAuthenticated])

  return (
    <div className="md:w-8/12 sm:w-7/12 flex flex-col gap-5 sm:mx-auto mx-6 my-4">
      <Stepper
        hidden={!!eligibilityTestResponseResult}
        classNames={{
          step: 'relative flex flex-col gap-2',
          separator: '-mx-2',
          stepLabel: 'absolute text-sm sm:text-nowrap text-center font-600 text-gray-700',
        }}
        active={currentStep}
        iconPosition="left"
        completedIcon={<TbCircleFilled size={10} />}
      >
        <Stepper.Step
          state="stepInactive"
          classNames={{
            stepLabel: 'w-20 sm:-left-11 -left-4',
          }}
          label={t('application:healthcare-professional-details-step')}
          icon={<TbCircleFilled size={10} />}
          completedIcon={<TbCheck size={20} />}
        >
          <HealthcareProfessionalAuth />
        </Stepper.Step>
        <Stepper.Step
          classNames={{
            stepLabel: 'w-20 sm:-left-11 -left-4',
          }}
          label={t('application:patient-details-step')}
          icon={<TbCircleFilled size={10} />}
          completedIcon={<TbCheck size={20} />}
        >
          <HealthcareProfessionalApplicantForm
            onProcessHealthcareProfessionalApplication={processHealthcareProfessionalApplication}
          />
        </Stepper.Step>
        <Stepper.Step
          classNames={{
            stepLabel: 'sm:-left-3 -left-4',
          }}
          label={t('application:checklist')}
          icon={<TbCircleFilled size={10} />}
          completedIcon={<TbCheck size={20} />}
        >
          <EligibilityTestForm
            healthcareProfessionalApplication={healthcareProfessionalApplication!}
            onEligibilityTestResponseConclusion={completeHealthcareProfessionalApplication}
          />
        </Stepper.Step>
      </Stepper>
      {eligibilityTestResponseResult && <EligibilityTestResult eligibilityTestResponseResult={eligibilityTestResponseResult} />}
    </div>
  )
}
