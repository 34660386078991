import {
  Button,
  Input, Text, TextInput, Title,
} from '@mantine/core'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IMaskInput } from 'react-imask'
import { cpfValidationRegex, emailValidationRegex, phoneValidationRegex } from '../../../shared/utils/regex'
import { Applicant, PatientAttributes } from '../../../interfaces/patient'
import { SignUpSession } from '../../../interfaces/auth'
import patientService from '../services/patient-service'
import patientAttributesUtils from '../utils/patient-attributes-utils'
import { useAuthUserActions } from '../../auth/store/auth-user-store'

interface Props {
  applicant: Applicant
  signUpSession: SignUpSession | null
}

export default function PatientForm({ signUpSession, applicant }: Props) {
  const { t } = useTranslation()
  const { setLoggedUser } = useAuthUserActions()

  const {
    handleSubmit, register, control, formState: { errors, isValid },
  } = useForm<PatientAttributes>({ mode: 'onTouched' })

  const handleSignUpHealthcareProfessional = async (patientAttributes: PatientAttributes) => {
    if (!signUpSession) return
    const signUpPatientAttributes = patientAttributesUtils.patientAttributesToSignUpPatientAttributes(patientAttributes, applicant.id)
    const session = await patientService.signUpPatient(signUpPatientAttributes, signUpSession.signUpToken)
    setLoggedUser(session.user)
  }

  return (
    <div className="flex flex-col 2xl:w-140 xl:w-140 lg:w-140 md:w-140 sm:mx-auto mx-2 sm:mt-5 mt-8">
      <Title order={1} fw={600} c="gray.9">{t('patient:patient-form:info')}</Title>
      <Text className="mt-2" c="gray.6">{t('patient:patient-form:info-description')}</Text>
      <form className="flex flex-col gap-3 mt-5" onSubmit={handleSubmit(handleSignUpHealthcareProfessional)}>
        <TextInput
          placeholder={t('common:name')}
          label={t('common:name')}
          required
          error={errors.name && errors.name.message}
          {...register('name', {
            required: { value: true, message: t('common:form-error:required-field') },
          })}
        />
        <TextInput
          placeholder={t('common:email')}
          label={t('common:email')}
          error={errors.email && errors.email.message}
          {...register('email', {
            validate: {
              validEmail: (email) => {
                if (!email) return true
                return emailValidationRegex.test(email) || (t('common:form-error:invalid-email') as string)
              },
            },
          })}
        />
        <Controller
          name="phone"
          control={control}
          rules={{
            required: { value: true, message: t('common:form-error:required-field') },
            validate: {
              validPhone: (phone) => phoneValidationRegex.test(phone) || t('common:form-error:invalid-phone'),
            },
          }}
          render={({ field: { ref, ...field } }) => (
            <Input.Wrapper label={t('common:cellphone')} required error={errors.phone && errors.phone.message}>
              <Input
                placeholder={t('common:cellphone')}
                required
                component={IMaskInput}
                mask="(00) 00000-0000"
                inputRef={ref}
                type="tel"
                {...field}
              />
            </Input.Wrapper>
          )}
        />
        <Controller
          name="taxId"
          control={control}
          rules={{
            required: { value: true, message: t('common:form-error:required-field') },
            validate: {
              validCpf: (taxId) => cpfValidationRegex.test(taxId) || t('common:form-error:invalid-cpf'),
            },
          }}
          render={({ field: { ref, ...field } }) => (
            <Input.Wrapper label={t('common:cpf')} required error={errors.taxId && errors.taxId.message}>
              <Input
                placeholder={t('common:cpf')}
                required
                component={IMaskInput}
                mask="000.000.000-00"
                inputRef={ref}
                type="tel"
                {...field}
              />
            </Input.Wrapper>
          )}
        />
        <Button
          fullWidth
          color="violet"
          disabled={!isValid}
          className="mt-1 sm:text-sm text-md sm:h-8 h-10"
          type="submit"
        >
          {t('common:confirm')}
        </Button>
      </form>
    </div>
  )
}
