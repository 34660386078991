import { SignUpSession } from '../../../interfaces/auth'
import { HealthcareProfessionalAttributes, SignUpHealthcaProfessionalAttributes } from '../../../interfaces/healthcare-professional'

const healthcareProfessionalToSignUpAttributes = (
  healtcareProfessionalAttributes: HealthcareProfessionalAttributes,
  signUpSession: SignUpSession,
): SignUpHealthcaProfessionalAttributes => {
  const healthcareProfessional = {
    council: healtcareProfessionalAttributes.council,
    uf: healtcareProfessionalAttributes.uf,
    licenseNumber: healtcareProfessionalAttributes.licenseNumber,
    specialty: healtcareProfessionalAttributes.specialty,
    gender: healtcareProfessionalAttributes.gender,
  }

  const user = {
    name: healtcareProfessionalAttributes.name,
    email: healtcareProfessionalAttributes.email || null,
    phone: signUpSession.phone,
  }

  return { ...healthcareProfessional, user }
}

export {
  healthcareProfessionalToSignUpAttributes,
}
