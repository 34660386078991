import {
  Button,
  Input,
  NumberInput,
  Select, Text, TextInput, Title,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { GiFemale, GiMale } from 'react-icons/gi'
import { Gender } from '../../../shared/enums'
import { emailValidationRegex } from '../../../shared/utils/regex'
import { councilOptions, healthcareSpecialtyOptions, ufOptions } from '../utils'
import ToggleCardGroup from '../../../shared/components/toggle-card-button/toggle-card-group'
import { HealthcareProfessionalAttributes } from '../../../interfaces/healthcare-professional'

interface Props {
  onHealthcareProfessionalSignUpSubmit: (healtcareProfessionalAttributes: HealthcareProfessionalAttributes) => void
}

export default function HealthcareProfessionalForm({ onHealthcareProfessionalSignUpSubmit }: Props) {
  const { t } = useTranslation()

  const {
    handleSubmit, register, control, formState: { errors, isValid }, watch, setValue,
  } = useForm<HealthcareProfessionalAttributes>({ mode: 'onTouched' })

  const council = watch('council')
  const gender = watch('gender')

  const genderOptions = [
    { value: Gender.Male, label: t('common:male'), icon: GiMale },
    { value: Gender.Female, label: t('common:female'), icon: GiFemale },
  ]

  const handleGenderSelection = (value: Gender) => {
    setValue('gender', value)
  }

  return (
    <div className="flex flex-col">
      <Title order={1} fw={600} c="gray.9">{t('healthcareProfessional:info')}</Title>
      <Text className="mt-2" c="gray.6">{t('common:can-edit')}</Text>
      <form className="flex flex-col gap-3 mt-5" onSubmit={handleSubmit(onHealthcareProfessionalSignUpSubmit)}>
        <TextInput
          placeholder={t('common:name')}
          label={t('common:name')}
          required
          error={errors.name && errors.name.message}
          {...register('name', {
            required: { value: true, message: t('common:form-error:required-field') },
          })}
        />
        <TextInput
          placeholder={t('common:email')}
          label={t('common:email')}
          error={errors.email && errors.email.message}
          {...register('email', {
            validate: {
              validEmail: (email) => {
                if (!email) return true
                return emailValidationRegex.test(email) || (t('common:form-error:invalid-email') as string)
              },
            },
          })}
        />
        <Controller
          name="council"
          control={control}
          rules={{
            required: { value: true, message: t('common:form-error:required-field') },
          }}
          render={({ field }) => (
            <Select
              label={t('healthcareProfessional:council')}
              placeholder={t('healthcareProfessional:council')}
              data={councilOptions}
              searchable
              required
              {...field}
            />
          )}
        />
        <Controller
          name="uf"
          control={control}
          rules={{
            required: { value: true, message: t('common:form-error:required-field') },
          }}
          render={({ field }) => (
            <Select
              label={t('healthcareProfessional:uf-council')}
              placeholder={t('common:uf')}
              data={ufOptions}
              searchable
              required
              {...field}
            />
          )}
        />
        {council && (
        <Controller
          name="licenseNumber"
          control={control}
          rules={{
            required: { value: true, message: t('common:form-error:required-field') },
          }}
          render={({ field }) => (
            <Input.Wrapper label={council.toUpperCase()} required error={errors.licenseNumber && errors.licenseNumber.message}>
              <NumberInput
                placeholder={council.toUpperCase()}
                allowDecimal={false}
                allowNegative={false}
                hideControls
                required
                {...field}
              />
            </Input.Wrapper>
          )}
        />
        )}
        <Controller
          name="specialty"
          control={control}
          rules={{
            required: { value: true, message: t('common:form-error:required-field') },
          }}
          render={({ field }) => (
            <Select
              label={t('common:specialty')}
              placeholder={t('common:specialty')}
              data={healthcareSpecialtyOptions}
              searchable
              required
              {...field}
            />
          )}
        />
        <div className="flex flex-col gap-1">
          <Text fw={500}>{t('common:gender')}</Text>
          <div className="flex">
            <ToggleCardGroup
              options={genderOptions}
              select={gender}
              onChange={handleGenderSelection}
            />
          </div>
        </div>
        <Button
          fullWidth
          color="violet"
          disabled={!isValid}
          className="mt-1 sm:text-sm text-md sm:h-8 h-10"
          type="submit"
        >
          {t('common:confirm')}
        </Button>
      </form>
    </div>
  )
}
