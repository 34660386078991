import EligibilityTestResponseResult from '../../../interfaces/eligibility-test/eligibility-test-result'
import EligibilityTestResultSummary from './eligibility-test-result-summary'
import EligibilityTestResultPending from './eligibility-test-result-pending'

interface Props {
  eligibilityTestResponseResult: EligibilityTestResponseResult
}

export default function EligibilityTestResult({ eligibilityTestResponseResult }: Props) {
  return (
    <div className="flex justify-center">
      {eligibilityTestResponseResult.status ? (
        <EligibilityTestResultSummary eligibilityTestResponseStatus={eligibilityTestResponseResult.status} />
      ) : (
        <EligibilityTestResultPending />
      )}
    </div>
  )
}
